import styled from 'styled-components';
import { ChevronIcon } from 'shared/assets/chevron-icon';

export const PrevNextButtonContainer = styled.div`
  display: none;

  @media screen and (min-width: 770px) {
    bottom: 16px;
    display: block;
    left: 50%;
    max-width: 1200px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s;
    transform: translateX(-50%);
    top: 0;
    visibility: hidden;
    width: 100%;
  }

  @media screen and (max-width: 1201px) {
    max-width: calc(100% - 50px);
  }
`;

export const Main = styled.div<{ isPreview: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  margin-top: ${({ isPreview }) => (isPreview ? '0' : '20px')};
  margin-left: ${({ isPreview }) => (isPreview ? '0' : '-25px')};
  position: relative;
  width: ${({ isPreview }) => (isPreview ? '100%' : '100vw')};

  &:hover ${PrevNextButtonContainer} {
    opacity: 1;
    visibility: visible;
  }
`;

export const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const CircleContainer = styled.button`
  align-items: center;
  border-radius: 50%;
  border: 1px solid #eaeff2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 48px;
  justify-content: center;
  padding: 0;
  width: 48px;
`;

export const sharedButtonStyles = styled(CircleContainer)`
  background-color: #fff;
  border: 0;
  cursor: pointer;
  display: none;
  outline: 0;
  pointer-events: auto;

  @media (min-width: 770px) {
    :hover {
      background-color: #bccad2;
    }

    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
`;

export const ButtonPrev = styled(sharedButtonStyles)`
  left: 8px;
`;

export const ButtonNext = styled(sharedButtonStyles)`
  right: 8px;
`;

export const ForwardArrow = styled(ChevronIcon)`
  transform: rotate(-90deg);
`;

export const BackArrow = styled(ChevronIcon)`
  transform: rotate(90deg);
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2px;
  width: auto;
  margin: 0 12px;
`;

export const DotButton = styled.span<{ selected: boolean }>`
  text-decoration: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#454e50' : '#e5e8ea')};
  :hover {
    background-color: ${({ selected }) => (selected ? '#454e50' : '#caced4')};
  }
  margin: 0 3px;
`;
