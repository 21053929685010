import styled, { css } from 'styled-components';
import { sharedButtonStyles } from 'src/components/carousel-slider/carousel-buttons';
import { Typography } from 'src/typography';

const GAP = 16;
const MOBILE_GAP = 12;

export const Wrapper = styled.div`
  margin: 32px auto 0;
  max-width: 534.5px;

  @media (min-width: 750.5px) {
    max-width: 750.5px;
  }

  @media (min-width: 966.5px) {
    max-width: 966.5px;
  }

  @media (min-width: 1187px) {
    max-width: 1187px;
  }

  ${({ $showCategoriesBorder }) =>
    $showCategoriesBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[95]};
      padding-bottom: 12px;
    `};
`;

export const WrapperShift = styled.div`
  margin: 0 -20px;
`;

export const Heading = styled(Typography.Heading)`
  margin: 0 20px;
`;

export const SliderContainer = styled.div`
  position: relative;

  ${({ $isMobileGrid }) =>
    $isMobileGrid &&
    css`
      @media screen and (max-width: 599px) {
        display: none;
      }
    `};
`;

export const Viewport = styled.div`
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Gradient = styled.div`
  display: block;
  height: 100%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.1s;
  width: 80px;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 100px;
  }
`;

export const GradientLeft = styled(Gradient)`
  background: linear-gradient(to left, #ffffff00, #fff 100%);
  left: 0;
`;

export const GradientRight = styled(Gradient)`
  background: linear-gradient(to right, #ffffff00, #fff 100%);
  right: 0;
`;

export const WatcherRight = styled.div`
  flex-shrink: 0;
  width: 1px;
`;

// Need the watcher divs to have a height
export const ItemsContainer = styled.div`
  display: flex;
`;

export const Items = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 20px;
`;

export const Grid = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ $alwaysUseMobileGap }) => ($alwaysUseMobileGap ? `${MOBILE_GAP}px` : `${GAP}px`)};
  list-style: none;
  margin: 0;
  padding: 20px;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export const Item = styled.li`
  ${({ $isMobileGrid, $isHidden, $useOneThirdMobileWidth, $alwaysUseMobileGap }) =>
    $isMobileGrid
      ? css`
          @media screen and (min-width: 600px) {
            padding-right: ${$alwaysUseMobileGap ? `${MOBILE_GAP}px` : `${GAP}px`};
          }

          @media screen and (max-width: 599px) {
            display: ${$isHidden ? 'none' : 'block'};
            width: ${$useOneThirdMobileWidth
              ? `calc(${(1 / 3) * 100}% - ${(($alwaysUseMobileGap ? MOBILE_GAP : GAP) * 2) / 3}px)`
              : 'auto'};
          }

          @media screen and (max-width: 430px) {
            width: calc(50% - ${($alwaysUseMobileGap ? MOBILE_GAP : GAP) / 2}px);
          }
        `
      : css`
          padding-right: ${MOBILE_GAP}px;

          @media screen and (min-width: 600px) {
            padding-right: ${$alwaysUseMobileGap ? `${MOBILE_GAP}px` : `${GAP}px`};
          }

          &:last-child {
            padding-right: 0;
          }
        `};
`;

export const ViewAllButton = styled.button`
  appearance: none;
  background: #e3e7e9;
  border: none;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  color: #2c3236;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: ${24 / 16};
  margin: 4px 20px 32px;
  padding: 16px;
  text-transform: ${({ theme }) => theme.customized.textTransforms.buttons};
  width: calc(100% - 40px);

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export const ButtonPrev = styled.button`
  ${sharedButtonStyles}
  left: 0;
`;

export const ButtonNext = styled.button`
  ${sharedButtonStyles}
  right: 0;
`;
