import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import usePaths from 'src/hooks/use-paths';
import ROUTES from 'src/routes';
import { RadiusOption } from 'types/graphql';

const radiusOptions = {
  [RadiusOption.rounded]: '9999px',
  [RadiusOption.roundedRectangle]: '12px',
  [RadiusOption.square]: 0,
};

type TextCardProps = {
  slug: string;
  label: string;
  style: RadiusOption;
  isMobileGrid: boolean;
};

export const TextCard = ({ slug, label, style, isMobileGrid }: TextCardProps): JSX.Element => {
  const { href: baseHref } = usePaths();

  return (
    <Link href={`${baseHref}${String(ROUTES.PRODUCTS)}${slug}`}>
      <Anchor href={`${baseHref}${String(ROUTES.PRODUCTS)}${slug}`} $radius={style} $isMobileGrid={isMobileGrid}>
        {label}
      </Anchor>
    </Link>
  );
};

const Anchor = styled.a`
  background: #fff;
  border: 2px solid #e3e7e9;
  border-radius: ${({ $radius }) => radiusOptions[$radius]};
  color: #121516;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: ${24 / 16};
  padding: ${({ $isMobileGrid }) => ($isMobileGrid ? `12px 5px` : `12px 20px`)};
  text-align: center;
  transition: border 0.2s;
  white-space: nowrap;

  @media screen and (min-width: 600px) {
    padding: 12px 20px;
  }

  &:hover {
    border-color: #646d72;
  }
`;
