import { useState } from 'react';

type UseListReturn = {
  isExpanded: boolean;
  viewAllItems: () => void;
};

export const useList = (): UseListReturn => {
  const [isExpanded, setIsExpanded] = useState(false);

  const viewAllItems = (): void => {
    setIsExpanded(true);
  };

  return {
    isExpanded,
    viewAllItems,
  };
};
