import { v4 as uuidV4 } from 'uuid';
import type { Banner } from './menu-image-banner';

export const TEST_SINGLE_IMAGE: Banner = {
  _id: uuidV4(),
  image:
    'https://images.dutchie.com/846c3f10949bfcfd2a665c9a3f95a929?w=600&h=200&auto=format&dpr=2&crop=faces&fit=crop&w=500&h=1&ixlib=react-7.2.0',
  mobileImage:
    'https://images.dutchie.com/846c3f10949bfcfd2a665c9a3f95a929?w=600&h=200&auto=format&dpr=2&crop=faces&fit=crop&w=500&h=1&ixlib=react-7.2.0',
  position: 0,
};

export const TEST_SECOND_IMAGE: Banner = {
  _id: uuidV4(),
  image:
    'https://images.dutchie.com/f34cec2ef027acd2b2d74d514057ea6e?w=600&h=200&auto=format&dpr=2&crop=faces&fit=crop&w=500&h=1&ixlib=react-7.2.0',
  mobileImage:
    'https://images.dutchie.com/846c3f10949bfcfd2a665c9a3f95a929?w=600&h=200&auto=format&dpr=2&crop=faces&fit=crop&w=500&h=1&ixlib=react-7.2.0',
  alt: 'FAKE DESCRIPTION 2',
  link: 'https://haha.business/',
  position: 1,
};

export const TEST_THIRD_IMAGE: Banner = {
  _id: uuidV4(),
  image:
    'https://images.dutchie.com/f2df3950176f5c1b2e54bdd1eefafc2a?w=600&h=200&auto=format&dpr=2&crop=faces&fit=crop&w=500&h=1&ixlib=react-7.2.0',
  mobileImage:
    'https://images.dutchie.com/846c3f10949bfcfd2a665c9a3f95a929?w=600&h=200&auto=format&dpr=2&crop=faces&fit=crop&w=500&h=1&ixlib=react-7.2.0',
  alt: 'FAKE DESCRIPTION 3',
  link: 'https://haha.business/',
  position: 2,
};

export const TEST_FOURTH_IMAGE: Banner = {
  _id: uuidV4(),
  image: 'https://via.placeholder.com/1184x395/070000.jpg',
  mobileImage: 'https://via.placeholder.com/1184x395/070000.jpg',
  alt: 'FAKE DESCRIPTION 4',
  position: 3,
};

export const TEST_FIFTH_IMAGE: Banner = {
  _id: uuidV4(),
  image: 'https://via.placeholder.com/1184x395/020000.jpg',
  mobileImage: 'https://via.placeholder.com/1184x395/070000.jpg',
  alt: 'FAKE DESCRIPTION 5',
  link: 'https://www.dutchie.com',
  position: 4,
};

export const TEST_SIXTH_IMAGE: Banner = {
  _id: uuidV4(),
  image: 'https://via.placeholder.com/1184x395/070022.jpg',
  mobileImage: 'https://via.placeholder.com/1184x395/070000.jpg',
  alt: 'FAKE DESCRIPTION 6',
  link: 'https://haha.business/',
  position: 5,
};

export const MAX_BANNERS = [
  TEST_SINGLE_IMAGE,
  TEST_SECOND_IMAGE,
  TEST_THIRD_IMAGE,
  TEST_FOURTH_IMAGE,
  TEST_FIFTH_IMAGE,
  TEST_SIXTH_IMAGE,
];

export const SINGLE_BANNER = [TEST_SINGLE_IMAGE];
