import React from 'react';

import { CategoriesMobileDisplayOption } from 'types/graphql';

import { ListProps } from '../categories-nav.types';
import { List } from './list';
import { Item } from './list.styles';
import { CompactCard } from '../cards/compact-card';
import { useList } from './use-list';

export const CompactList = ({ categories, style, mobileDisplay, showCategoriesBorder }: ListProps): JSX.Element => {
  const { isExpanded, viewAllItems } = useList();

  return (
    <List
      mobileDisplay={mobileDisplay}
      enableViewAll={categories.length > 5}
      isExpanded={isExpanded}
      viewAllItems={viewAllItems}
      showCategoriesBorder={showCategoriesBorder}
    >
      {categories.map((category, index) => (
        <Item
          key={category.key}
          $isMobileGrid={mobileDisplay === CategoriesMobileDisplayOption.grid}
          $isHidden={!isExpanded && index > 5}
          $useOneThirdMobileWidth
        >
          <CompactCard category={category} style={style} />
        </Item>
      ))}
    </List>
  );
};
