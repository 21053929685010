import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { RadiusOption } from 'types/graphql';
import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useHardAgeGate } from 'src/hooks/use-hard-age-gate';
import ROUTES from 'src/routes';
import Imgix from 'shared/components/imgix';
import { getCategoryPhoto } from 'shared/helpers/dispensaries';
import { Category } from '../categories-nav.types';

const radiusOptions = {
  [RadiusOption.rounded]: '16px',
  [RadiusOption.roundedRectangle]: '12px',
  [RadiusOption.square]: 0,
};

type CompactCardProps = {
  category: Category;
  style: RadiusOption;
};

export const CompactCard = ({ category, style }: CompactCardProps): JSX.Element => {
  const { href: baseHref } = usePaths();
  const { dispensary } = useDispensary();
  const { key, label } = category;

  const { hardAgeGate, dispensaryLogoImage } = useHardAgeGate();
  const image = hardAgeGate ? dispensaryLogoImage : getCategoryPhoto(dispensary, category);
  const imgixParams = {
    width: 224,
  };

  return (
    <Link href={`${baseHref}${String(ROUTES.PRODUCTS)}${key}`}>
      <Anchor href={`${baseHref}${String(ROUTES.PRODUCTS)}${key}`} $radius={style}>
        <ImageWrapper>
          <Image src={image} disableSrcSet imgixParams={imgixParams} htmlAttributes={{ alt: '' }} />
        </ImageWrapper>

        {label}
      </Anchor>
    </Link>
  );
};

const Anchor = styled.a`
  background: #fff;
  border: 1px solid #e3e7e9;
  border-radius: ${({ $radius }) => radiusOptions[$radius]};
  color: #121516;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: ${24 / 16};
  padding: 12px;
  text-align: center;
  transition: box-shadow 0.1s;
  white-space: nowrap;
  width: 100%;

  @media screen and (min-width: 600px) {
    font-size: 18px;
    line-height: ${22 / 18};
    padding: 16px;
    width: 144px;
  }

  &:hover {
    box-shadow: 0 5px 14px #0000001a;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 51px;
  justify-content: center;
  margin: 0 auto 8px;
  overflow: hidden;
  width: 96px;

  @media screen and (min-width: 600px) {
    height: 60px;
    margin-bottom: 12px;
    width: 112px;
  }
`;

const Image = styled(Imgix)`
  display: block;
  max-height: 100%;
`;
