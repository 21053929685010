import React from 'react';

import { CategoriesMobileDisplayOption } from 'types/graphql';

import { ListProps } from '../categories-nav.types';
import { List } from './list';
import { Item } from './list.styles';
import { TextCard } from '../cards/text-card';

export const TextList = ({ categories, style, mobileDisplay, showCategoriesBorder }: ListProps): JSX.Element => {
  const isMobileGrid = mobileDisplay === CategoriesMobileDisplayOption.grid;

  return (
    <List
      mobileDisplay={mobileDisplay}
      enableViewAll={false}
      showCategoriesBorder={showCategoriesBorder}
      alwaysUseMobileGap
    >
      {categories.map((category) => (
        <Item key={category.key} $isMobileGrid={isMobileGrid} $useOneThirdMobileWidth $alwaysUseMobileGap>
          <TextCard slug={category.key} label={category.label} style={style} isMobileGrid={isMobileGrid} />
        </Item>
      ))}
    </List>
  );
};
