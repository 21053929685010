import React, { ComponentType } from 'react';

import { CategoriesMobileDisplayOption, CategoriesTypeOption, RadiusOption } from 'types/graphql';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { ListProps } from './categories-nav.types';
import { LargeList } from './lists/large-list';
import { TextList } from './lists/text-list';
import { StackedList } from './lists/stacked-list';
import { CompactList } from './lists/compact-list';

type Category = {
  key: string;
  label: string;
  value: string;
};

type CategoriesNavProps = {
  categories: Category[];
  showCategoriesBorder: boolean;
};

const getListComponent = (type: CategoriesTypeOption): ComponentType<ListProps> => {
  switch (type) {
    case CategoriesTypeOption.text:
      return TextList;
    case CategoriesTypeOption.compact:
      return CompactList;
    case CategoriesTypeOption.stacked:
      return StackedList;

    // Large type is the existing component, so we will consider that the default
    default:
      return LargeList;
  }
};

export const CategoriesNav = ({ categories, showCategoriesBorder }: CategoriesNavProps): JSX.Element => {
  const { dispensary } = useDispensary();
  const { webCustomizationSettingsV2 } = dispensary;
  const { style, type, mobileDisplay } = webCustomizationSettingsV2?.categories ?? {
    mobileDisplay: CategoriesMobileDisplayOption.grid,
    type: CategoriesTypeOption.large,
    style: RadiusOption.roundedRectangle,
  };

  const ListComponent = getListComponent(type);

  return (
    <ListComponent
      categories={categories}
      mobileDisplay={mobileDisplay}
      style={style}
      showCategoriesBorder={showCategoriesBorder}
    />
  );
};
