import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { RadiusOption } from 'types/graphql';
import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useHardAgeGate } from 'src/hooks/use-hard-age-gate';
import ROUTES from 'src/routes';
import Imgix from 'shared/components/imgix';
import { getCategoryPhoto } from 'shared/helpers/dispensaries';
import { Category } from '../categories-nav.types';

const radiusOptions = {
  [RadiusOption.rounded]: '16px',
  [RadiusOption.roundedRectangle]: '12px',
  [RadiusOption.square]: 0,
};

type CompactCardProps = {
  category: Category;
  style: RadiusOption;
  isMobileGrid: boolean;
};

export const LargeCard = ({ category, style, isMobileGrid }: CompactCardProps): JSX.Element => {
  const { href: baseHref } = usePaths();
  const { dispensary } = useDispensary();
  const { key, label } = category;

  const { hardAgeGate, dispensaryLogoImage } = useHardAgeGate();
  const image = hardAgeGate ? dispensaryLogoImage : getCategoryPhoto(dispensary, category);
  const imgixParams = {
    width: 268,
  };

  return (
    <Link href={`${baseHref}${String(ROUTES.PRODUCTS)}${key}`}>
      <Anchor href={`${baseHref}${String(ROUTES.PRODUCTS)}${key}`} $radius={style} $isMobileGrid={isMobileGrid}>
        {label}

        <ImageWrapper>
          <Image src={image} disableSrcSet imgixParams={imgixParams} htmlAttributes={{ alt: '' }} />
        </ImageWrapper>
      </Anchor>
    </Link>
  );
};

const Anchor = styled.a`
  background: #fff;
  border: 1px solid #e3e7e9;
  border-radius: ${({ $radius }) => radiusOptions[$radius]};
  color: #121516;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: ${22 / 18};
  padding: 16px;
  transition: box-shadow 0.1s;
  white-space: nowrap;
  width: ${({ $isMobileGrid }) => ($isMobileGrid ? '100%' : '146px')};

  @media screen and (min-width: 600px) {
    font-size: 20px;
    line-height: ${24 / 20};
    padding: 20px 24px;
    width: 184px;
  }

  &:hover {
    box-shadow: 0 5px 14px #0000001a;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 67px;
  justify-content: center;
  margin: 16px auto 0;
  overflow: hidden;

  @media screen and (min-width: 600px) {
    height: 77px;
    margin-top: 20px;
  }
`;

const Image = styled(Imgix)`
  display: block;
  max-height: 100%;
  max-width: 100%;
`;
