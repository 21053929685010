import React from 'react';

import { CategoriesMobileDisplayOption } from 'types/graphql';

import { ListProps } from '../categories-nav.types';
import { List } from './list';
import { Item } from './list.styles';
import { LargeCard } from '../cards/large-card';
import { useList } from './use-list';

export const LargeList = ({ categories, style, mobileDisplay, showCategoriesBorder }: ListProps): JSX.Element => {
  const { isExpanded, viewAllItems } = useList();
  const isMobileGrid = mobileDisplay === CategoriesMobileDisplayOption.grid;

  return (
    <List
      mobileDisplay={mobileDisplay}
      enableViewAll={categories.length > 5}
      isExpanded={isExpanded}
      viewAllItems={viewAllItems}
      showCategoriesBorder={showCategoriesBorder}
    >
      {categories.map((category, index) => (
        <Item
          key={category.key}
          $isMobileGrid={isMobileGrid}
          $isHidden={!isExpanded && index > 5}
          $useOneThirdMobileWidth
        >
          <LargeCard category={category} style={style} isMobileGrid={isMobileGrid} />
        </Item>
      ))}
    </List>
  );
};
