/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import styled from 'styled-components';

import { DispensaryMenuBannerColors } from 'shared/constants';

import RichTextHtmlWrapper from 'shared/components/rich-text-html-wrapper';
import SvgChevronIcon from 'assets/chevron-icon';
import { tracker } from 'src/utils/analytics';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

export default function MenuBanner({ htmlContent, color, className }) {
  const { dispensary } = useDispensary();

  const isContentLongAndCollapsed = htmlContent?.length > 500 && (dispensary.menuBannerTextBannerCollapsed ?? true);

  const [isCollapsed, setIsCollapsed] = useState(isContentLongAndCollapsed);

  const handleExpand = () => {
    setIsCollapsed(false);
    tracker.gaGTMClickEvent({
      eventCategory: `text banner impression`,
      eventAction: `expanded`,
    });
  };

  const handleCollapse = () => {
    setIsCollapsed(true);
    tracker.gaGTMClickEvent({
      eventCategory: `text banner impression`,
      eventAction: `collapse`,
    });
  };

  return (
    <Container
      className={className}
      color={color || 'green'}
      isLongHtmlContent={isCollapsed}
      data-cy='menu-banner'
      data-test='menu-banner'
      data-testid='menu-banner'
      onClick={isCollapsed ? handleExpand : undefined}
    >
      <StyledRichTextWrapper isLongHtmlContent={isCollapsed} color={color || 'green'}>
        <RichTextHtmlWrapper html={htmlContent} />
      </StyledRichTextWrapper>
      <StyledChevronWrapper isLongHtmlContent={isCollapsed} onClick={isCollapsed ? handleExpand : handleCollapse}>
        <SvgChevronIcon fill={color} width='16px' />
      </StyledChevronWrapper>
    </Container>
  );
}

const Container = styled.div`
  background: ${(props) => DispensaryMenuBannerColors[props.color].background};
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  color: ${(props) => DispensaryMenuBannerColors[props.color].color};
  cursor: default;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;
  line-height: 1.65;
  margin: 50px auto 60px;
  max-width: 1200px;
  padding: 24px;
  word-break: break-word;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 25px auto 40px;
    padding: 24px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  }

  ${({ isLongHtmlContent }) =>
    isLongHtmlContent &&
    `
      height: 150px;
      overflow: hidden;
      cursor: pointer;
    `}
`;

const StyledChevronWrapper = styled.div`
  display: flex;
  height: min-content;

  ${({ isLongHtmlContent }) =>
    !isLongHtmlContent &&
    `
      cursor: pointer;
      svg {
       transform: rotate(180deg);
      }
    `}
`;

const StyledRichTextWrapper = styled.div`
  ${({ isLongHtmlContent, color }) =>
    isLongHtmlContent &&
    `
      height: 126px;
      -webkit-mask-image: linear-gradient(180deg, ${DispensaryMenuBannerColors[color].background} 60%, transparent);

      &:hover {
        -webkit-mask-image: linear-gradient(180deg, ${DispensaryMenuBannerColors[color].background} 100%, transparent);
      }
    `}
`;
